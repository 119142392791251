<template>
  <base-section
    id="company-value"
    space="56"
  >
    <v-container>
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="12"
        >
          <base-business-info title="NILAI PERUSAHAAN (M.A.N.T.A.P)">
          </base-business-info>
          <v-img
            :src="require('@/assets/company-value.png')"
            />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCompanyValue',
  }
</script>
